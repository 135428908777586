import { Directive, Input, NgModule, TemplateRef } from '@angular/core';

@Directive({
    selector: 'amm-template[templateName]',
    standalone:true,
})
export class AmmTemplateNameDirective {

    @Input() templateName?: string;

    constructor(public template: TemplateRef<any>) {
    }
}

