<div class="password">
  <div class="inputLabel text mb-2">{{getLabel()}}</div>
  <p-password *ngIf="noFeedback()" class="{{getPasswordClass()}}" [formControl]="getPasswordFrnCnt()"
    [toggleMask]="true" [feedback]="false" (ngModelChange)="passwordEmit()" [style]="{'width':'100%'}"
    [inputStyle]="{'width':'100%'}" [placeholder]="getPlaceHolder()" />

  <p-password *ngIf="!noFeedback()" class="{{getPasswordClass()}}" [formControl]="getPasswordFrnCnt()"
    [toggleMask]="true" (ngModelChange)="passwordEmit()" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"
    [placeholder]="getPlaceHolder()">
    <ng-template pTemplate="header">
      <div class="title text">Pick a password</div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="footerContent">
        <p-divider></p-divider>
        <p class="text details">Suggestions</p>
        <ul class="rules pl-1">
          <li class="text details items-center">• At least one lowercase</li>
          <li class="text details items-center">• At least one uppercase</li>
          <li class="text details items-center">• At least one numeric</li>
          <li class="text details items-center">• Minimum 8 characters</li>
        </ul>
      </div>
    </ng-template>
  </p-password>
  <small class="errorInfo overflow-hidden mt-1" *ngIf="hasError()">{{getErrorInfo()}}</small>
</div>